

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #151515;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Video Container */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Video */
.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Gradient Overlay */
.video-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.innerShadow::before{
  background: linear-gradient(180deg, #141414 27.93%, rgba(20, 20, 20, 0.00) 93.28%), rgba(211, 211, 211, 0) 50% / cover no-repeat;
 
}
/* Fonts */

.medium-pop{
  font-family: 'Poppins, sans-serif';
  font-weight:600;
}


/* marque */
.wrapper {
  max-width: 100%;
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 10s linear infinite;
}

.marquee p {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

