@tailwind base;
@tailwind components;
@tailwind utilities;



.sec1gradient{
    border-radius: 3.5vw 3.5vw 0 0;
    display: flex;
    width: 77.417vw;
    height: 30.667vw;
    align-items: end;
    background-image: linear-gradient(#5C39E9,#AE1FF1,#5EFFBB,#0C7CFF);
    justify-content: center;
}

/* section 1 */
.transperant-black{
    border-radius: 2rem;
    background: rgba(16, 16, 16, 0.86);
    backdrop-filter: blur(15.5px);
}

.gradiantbtn{
    background-image: linear-gradient(#5C39E9,#AE1FF1,#5EFFBB,#0C7CFF);
}



.gradiantbtn{
    background-image: linear-gradient(#5C39E9,#AE1FF1,#5EFFBB,#0C7CFF);
}
.bg-text-gradient {
    background: linear-gradient(180deg, #6507FF 0%, rgba(137, 44, 255, 0.64) 45.1%, #7D2EFF 67.5%, rgba(109, 96, 255, 0.00) 99.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.paragraph{
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.get-started-btn{
    width: 178px;
    height: 62px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #6507FF;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

